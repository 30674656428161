import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open Source -`}</strong>{` This tutorial assumes you have installed the `}<inlineCode parentName="li">{`step`}</inlineCode>{` and `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` software, and that you've just initialized a CA using the steps in `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://smallstep.com/certificate-manager"
          }}>{`Smallstep Certificate Manager`}</a>{` -`}</strong>{` Please get in touch with `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`Smallstep Customer Success`}</a>{` if you want to use a RSA certificate chain.`}</li>
    </ul>
    <h2>{`Instructions`}</h2>
    <p>{`Some legacy applications require a certificate chain that uses RSA keys, but  `}<inlineCode parentName="p">{`step ca init`}</inlineCode>{` creates a PKI that uses ECDSA keys by default. In this tutorial, you will replace the default ECDSA chain with an RSA chain.`}</p>
    <p>{`First, stop your `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` server if it is running.`}</p>
    <p>{`Next, delete your existing PKI and create RSA root and intermediate certificates and keys.
`}<strong parentName="p">{`This step will overwrite your existing CA.`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step certificate create "Example Root CA" \\
    $(step path)/certs/root_ca.crt \\
    $(step path)/secrets/root_ca_key \\
    --profile root-ca \\
    --kty RSA
step certificate create "Example Intermediate CA" \\
    $(step path)/certs/intermediate_ca.crt \\
    $(step path)/secrets/intermediate_ca_key \\
    --profile intermediate-ca \\
    --ca $(step path)/certs/root_ca.crt \\
    --ca-key $(step path)/secrets/root_ca_key \\
    --kty RSA
`}</code></pre>
    <p>{`Change the certificate subject names as desired.
You'll be prompted to supply a password to encrypt your private keys.`}</p>
    <p>{`You can now restart `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` server.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      